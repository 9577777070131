import React, { useState } from "react";
import { FaArrowRight, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

const ProjeBazliDetails = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* service area start */}
      <div className="service-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="service-details-wrap">
                <div className="thumb">
                  <img src="assets/img/service/5.png" alt="Transpro" />
                  <div className="icon">
                    <img
                      src="assets/img/service/proje-icon.png"
                      alt="Transpro"
                    />
                  </div>
                </div>
                <h2>PROJE BAZLI TAŞIMACILIK</h2>
                <p>
                  Haluk Görkem Lojistik olarak, müşterilerimizin büyük ve
                  kompleks projeleri için özel lojistik çözümler sunuyoruz.
                  Proje bazlı taşımacılık hizmetimiz, enerji, inşaat, madencilik
                  gibi sektörlerdeki büyük ölçekli taşımacılık ihtiyaçlarınızı
                  karşılamak üzere planlanmıştır. Her projenin kendine özgü
                  gereksinimlerine göre, uzman ekibimiz ve geniş araç filomuzla
                  en etkili ve güvenilir çözümleri sunuyoruz.
                </p>
                <p>
                Proje bazlı taşımacılık hizmetimizin başlıca özellikleri:
                </p>
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div className="thumb mb-lg-0 mb-4">
                      <img src="assets/img/service/proje-bazli.jpeg" alt="Transpro" />
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <h4 className="subtitle"></h4>
                    <ul className="list-inner-wrap">
                      <li> Kapsamlı Lojistik Planlama</li>
                      <li> Özel Araç ve Ekipman Kullanımı</li>
                      <li> Zamanında ve Güvenli Teslimat</li>
                      <li> Ulusal ve Uluslararası Projeler</li>
                      <li>Mevzuata Uygunluk ve İzinler</li>
                      <li> Risk Yönetimi</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-area">
                <div className="widget widget_catagory">
                  <h4 className="widget-title">
                    HİZMETLERİMİZ
                    <span className="dot" />
                  </h4>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/blog">
                        Yurt İçi Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        Yurt Dışı Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        Parsiyel Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        Proje Bazlı Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div
                  className="widget widget_support text-center mb-0"
                  style={{
                    background: "url(assets/img/widget/support-bg.png)",
                  }}
                >
                  <h4 className="widget-title style-white">
                    7/24 HİZMET <span className="dot" />
                  </h4>
                  <p>
                    Haluk Görkem Lojistik olarak, müşterilerimize kesintisiz
                    lojistik hizmeti sunuyoruz.
                  </p>
                  <p className="contact">
                    <FaRegEnvelope />
                    halukgorkemlojistik06@gmail.com
                  </p>
                  <p className="contact mb-0">
                    <FaPhoneAlt />
                    0(532) 270 46 79
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="XM6kTQPzzpQ"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ProjeBazliDetails;
