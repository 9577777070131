import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import ParsiyelDetails from "../components/ParsiyelDetails";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));

const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const ParsiyelTasimacilik = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarOne />

          {/* Breadcrumb */}
          <Breadcrumb title={"PARSİYEL TAŞIMACILIK"} />

          {/* Service Details Inner */}
          <ParsiyelDetails />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default ParsiyelTasimacilik;
