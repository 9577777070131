import React, { useState } from "react";
import { FaArrowRight, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

const ParsiyelDetails = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* service area start */}
      <div className="service-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="service-details-wrap">
                <div className="thumb">
                  <img src="assets/img/service/4.png" alt="Transpro" />
                  <div className="icon">
                    <img
                      src="assets/img/service/service-icon-3.png"
                      alt="Transpro"
                    />
                  </div>
                </div>
                <h2>PARSİYEL TAŞIMACILIK</h2>
                <p>
                  Haluk Görkem Lojistik olarak, müşterilerimizin ihtiyaçlarına
                  uygun esnek lojistik çözümler sunuyoruz. Parsiyel taşımacılık
                  hizmetimiz, yüklerinizi komple bir araç kiralamadan, diğer
                  müşterilerin yükleri ile birlikte uygun maliyetli bir şekilde
                  taşımanıza olanak tanır. Bu sayede, daha küçük hacimli
                  gönderileriniz için de ekonomik çözümlerle nakliye
                  işlemlerinizi gerçekleştirebilirsiniz.
                </p>
                <p>
                Parsiyel taşımacılık hizmetimiz şu avantajları içerir:
                </p>
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div className="thumb mb-lg-0 mb-4">
                      <img src="assets/img/service/parsiyel.jpeg" alt="Transpro" />
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <h4 className="subtitle"></h4>
                    <ul className="list-inner-wrap">
                      <li> Maliyet Avantajı</li>
                      <li> Esnek Sevkiyat Planları</li>
                      <li> Düzenli Yük Takibi</li>
                      <li> Kapsamlı Lojistik Destek</li>
                      <li> Çeşitli Yük Türlerine Uygun Taşıma</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-area">
                <div className="widget widget_catagory">
                  <h4 className="widget-title">
                    HİZMETLERİMİZ
                    <span className="dot" />
                  </h4>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/blog">
                        Yurt İçi Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        Yurt Dışı Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        Parsiyel Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog">
                        Proje Bazlı Taşımacılık{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div
                  className="widget widget_support text-center mb-0"
                  style={{
                    background: "url(assets/img/widget/support-bg.png)",
                  }}
                >
                  <h4 className="widget-title style-white">
                    7/24 HİZMET <span className="dot" />
                  </h4>
                  <p>
                    Haluk Görkem Lojistik olarak, müşterilerimize kesintisiz
                    lojistik hizmeti sunuyoruz.
                  </p>
                  <p className="contact">
                    <FaRegEnvelope />
                    halukgorkemlojistik06@gmail.com
                  </p>
                  <p className="contact mb-0">
                    <FaPhoneAlt />
                    0(532) 270 46 79
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="XM6kTQPzzpQ"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ParsiyelDetails;
